// ENV_URL definido no webpack.config
// const ENV = ENV_URL;
// const ENV = 'prod'; // força produção pra teste de dados tempo real
//const ENV = 'dev';

const getURI = (api) => {
   
//    console.log('URL');
//    console.log (process.env.REACT_APP_AMBIENTE);
//    console.log(window.location.href);
//   console.log(window.location.host);
    
    // se rodar com npm start
    // console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV == 'development')  
        return 'http://localhost:2200';

    //else if (process.env.NODE_ENV == 'production')
    let prefix = window.location.host.startsWith("localhost") ? 'http://' : 'https://';
    //console.log(prefix);
    return (prefix + window.location.host);

    //if (process.env.REACT_APP_AMBIENTE)
    //   return process.env.REACT_APP_AMBIENTE;
	// else		
    //  return 'http://localhost:2200';
}

const endpointsConfig = {
    login:          `${getURI('login')}/api/v1/login`,
    user:           `${getURI('user')}/api/v1/user`,
    role:           `${getURI('role')}/api/v1/role`,
    room:           `${getURI('room')}/api/v1/room`,
    roomType:       `${getURI('roomType')}/api/v1/roomType`,
    stay:           `${getURI('stay')}/api/v1/stay`,
//    card:           `${getURI('card')}/api/v1/card`,
//    configCard:     `${getURI('configCard')}/api/v1/configCard`,
    publicDoor:     `${getURI('publicDoor')}/api/v1/publicDoor`,
    elevator:       `${getURI('elevator')}/api/v1/elevator`,
    configHotel:    `${getURI('configHotel')}/api/v1/configHotel`,
    configJson:     `${getURI('configJson')}/api/v1/configJson`,
    staff:          `${getURI('staff')}/api/v1/staff`,
    staffGroup:     `${getURI('staffGroup')}/api/v1/staffGroup`,
    staffProfile:   `${getURI('staffProfile')}/api/v1/staffProfile`,
    staffAuth:      `${getURI('staffAuth')}/api/v1/staffAuth`,
    staffTerm:      `${getURI('staffTerm')}/api/v1/staffTerm`,
    timeTable:      `${getURI('timeTable')}/api/v1/timeTable`,
    temporary:      `${getURI('temporary')}/api/v1/temporary`,
    temporaryGroup: `${getURI('temporaryGroup')}/api/v1/temporaryGroup`,
    emergency:      `${getURI('emergency')}/api/v1/emergency`,
    emergencyGroup: `${getURI('emergencyGroup')}/api/v1/emergencyGroup`,
    defaultAccess:  `${getURI('defaultAccess')}/api/v1/defaultAccess`,
    histLockerQuery:    `${getURI('histLockerQuery')}/api/v1/histLockerQuery`,
    histLockerRecords:  `${getURI('histLockerRecords')}/api/v1/histLockerRecords`,
    histCards:          `${getURI('histCards')}/api/v1/histCards`,
    histInitCards:      `${getURI('histInitCards')}/api/v1/histInitCards`,
    histUsers:          `${getURI('histUsers')}/api/v1/histUsers`,
    histStaffTerm:          `${getURI('histStaffTerm')}/api/v1/histStaffTerm`,
    histStaffAuth:          `${getURI('histStaffAuth')}/api/v1/histStaffAuth`,
    histStay:           `${getURI('histStay')}/api/v1/histStay`,
    customer:           `${getURI('customer')}/api/v1/customer`,
    publicInfo:           `${getURI('publicInfo')}/api/v1/publicInfo`,
    
    util:           `${getURI('util')}/api/v1/util`,
    importAccessDB:       `${getURI('importAccessDB')}/api/v1/importAccessDB`,
    dashboard:      `${getURI('dashboard')}/api/v1/dashboard`,
}

export default endpointsConfig;
