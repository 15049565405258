import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import AuthService from './service/AuthService';
import SplashScreen from './views/SplashScreen/SplashScreen';

const loading = () => <SplashScreen main/>;

//<div className="animated fadeIn pt-3 text-center">Carregando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login'));
const PaasswordResetForm = React.lazy(() => import('./views/PasswordReset/PasswordResetForm'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <ReactNotification />
        <React.Suspense fallback={loading()}>
        <Switch>
            <Route path="/resetPass/:id" name="Reset de Senha de Usuário" render={props => <PaasswordResetForm {...props} />} />
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route path="/" name="Home" render={props => {
              if (AuthService.loggedIn()) {
                return <DefaultLayout {...props} />
              }
              return <Redirect to="/login" />
            }} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
